<template >
  <div class="msp-grafico-andature">
    <template v-for="(dettaglio, i_d) in dettagli" >
        <template v-for="(ripetutaDettaglio, i_rd) in minRipetute(dettaglio)">
      <template v-for="(serie, i_s) in dettaglio.serie">
        <template v-for="(ripetuta, i_r) in +serie.ripetute">
          <div class="andatura" :class="'andatura-'+andatura.des_zona" v-for="(andatura, i_a) in serie.andature" 
          :key="unique+'-'+i_d+'-'+i_rd+'-'+i_s+'-'+i_r+'-'+i_a" :style="getStyle(andatura)">
          <div class="andatura-bar"></div>

            <div class="andatura-tooltip" v-html="renderZona(andatura)"></div>
          </div>
        </template>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";
import zone from "@cached/zone.json";
import distanze from "@lib/distanze.js";
import {methods as methodsAndaturaZonaRange} from '@src/js/vue/mixins/andaturaZonaRange.js'
import _msp_constants from '../../../js/msp/constants';

const component = {
  props: ['dettagli','zone-attive', 'sport_id', 'colore', 'tests', 'espandi'],
  mixins: [
    allenamentoCalcoli,
  ],
  data: function () {
    return {
      unique: this.MSP().unique,
      maxHeight: zone.reduce((acc,el)=>(Math.max(acc,+el.posizione)),1)
    }
  },
  computed: {
    zone: function () {
      return zone;
    },

    hasTests: function () {
        return (this.filtered_tests && this.filtered_tests.length);
    },

    filtered_tests: function () {
      if (!this.espandi) { return []; }
      if (!(this.tests && this.tests.length)) { return []; }
      return this.tests.filter((el)=>(el.sport_id===this.sport_id));
    },

    totale: function () {
      return this.dettagli.reduce((acc,d)=>{
        if (!d.serie) { return acc; }
        d.serie.forEach((s)=>{
          if (!s.andature) {
            return acc;
          }
          s.andature.forEach((a)=>{
            let sec = this.MSP().convertiMtSecAndatura(a, this.zoneAttive).sec;
            acc = acc + sec;
          })
        })
        return acc;
      }
      ,0);
    }
  },
  methods: {
    ...methodsAndaturaZonaRange,

    minRipetute: function (dettaglio) {
        let ripetute = +dettaglio.ripetute;
        if (!ripetute) return 1;
        return ripetute;
    },

    

    renderZona: function (andatura) {
        const sep = "<br>";
        if (!this.espandi) { return ""; }

        let zonaRange = this.zonaRangeDescrizione(andatura);
        let zone = [];
        if (!this.hasTests) {
            if (!this.zoneAttive) return "";
            let zona = this.zoneAttive.find(el=>el.id == andatura.andatura_zona_id);
            if (!zona) return "";
            return zona.codice + zonaRange;
        }
        this.filtered_tests.forEach((test)=>{
            let zona = this.makeZonaForRange(test,andatura.andatura_zona_id);
            if (zona) {
                zone.push(zona);
            }
        });
        if (!zone.length) { return ""; }

        let results = [];

        results.push(`<strong class='codice-zona'>${zone[0].zona.codice} ${zonaRange}</strong>`);
        zone.forEach((zona_obj)=>{
            let params = [zona_obj.um_id, zona_obj.um_descrizione];
            if (this.sport_id != _msp_constants.ID_NUOTO) {
                results.push(this.formatDescrizioneZona(zona_obj, andatura)+sep);
            } else {
                // Nel nuoto, le zone attive sono definite per le distanze.
                // Le distanze sono 5: 50, 100, 200, 400, continue
                // Le zone sono A1, A2, B1, B2, C1, C2, C3.
                // Ci sarnno quindi A1 a 50, A1 a 100... e così via.
                // Nella tabella devo fare un render della zona per ogni distanza.

                distanze.forEach((distanza)=>{
                    let zona = this.zoneAttive.find((el)=>{
                        let found =  (+el.id == +andatura.andatura_zona_id) && (el.distanza == distanza.id);
                        return found;
                    });
                    let value;
                    if (zona) {
                        value = this.MSP().formatRisultatoByUmId((zona.da+zona.a)/2, ...params) 
                    } else {
                        value = "/";
                    }

                    results.push(`${distanza.name} : ${value} ${sep}` );
                });
            }
        });
        return results.join("");
    },

    /** Cerco la zona attiva in base al codice.
     * Nel nuoto, prendo quella su distanza 100mt. */
    getZonaAttiva: function (id) {
        let filtered;
        if (_msp_constants.ID_NUOTO == this.sport_id) {
            filtered = this.zoneAttive.find(el=>{
                let found = (+el.id == +id) && (el.distanza == "100");
                return found;
            });
        } else {
            filtered = this.zoneAttive.find(el=>+el.id===+id);
        }
        return filtered;
    },

    getZona: function (id) {
        let filtered = this.zone.find(el=>+el.id===+id);
        return filtered;
    },

    getPercentuale: function (andatura) {
      let percentuale = 100*this.MSP().convertiMtSecAndatura(andatura, this.zoneAttive).sec/this.totale;
      if (isNaN(percentuale)) {
          return 0;
      } else {
          return percentuale;
      }
    },

    getStyle: function (andatura) {
      let a_style=[];
      if (!andatura.andatura_zona_id) { return ""; }
      const zonaAttiva = this.getZonaAttiva(andatura.andatura_zona_id);
      let zona = this.getZona(andatura.andatura_zona_id);
      zona = {
          ...zona,
          ...zonaAttiva,
      };
      const width = this.getPercentuale(andatura);
      a_style.push(`--width:${width}`);
      let range;
      let percentuale = ((+andatura.andatura_zona_range_da)+(+andatura.andatura_zona_range_a))/2;
      let da = +zona.posizione;
      let a = da + 1;
      if (this.hasCustomRange(andatura)) {
          range = da + (a-da)*percentuale/100;
      } else {
          range = da;
      }
      const height = range / (this.maxHeight);
      a_style.push(`--height:${height}`);
      if (this.colore) {
        const color = zona.colore;
        a_style.push(`--color:${color}`);
      }
      return a_style.join(";");
    },

    totaleDettaglio: function (dettaglio) {
      const zone_attive = this.zoneAttive;
      if (!(dettaglio.serie && dettaglio.serie.length) ) {
        return false;
      }
      let totaliZone = {};
      dettaglio.serie.forEach(
        (s)=>{
          if (!s.andature) { return; }
          let totale = this.MSP().sommaTotale(s.ripetute, s.andature, zone_attive, null) || false;
          if (totale) {
            var z = totale.zone;
            for(let iz in z){
              if(!totaliZone[iz]) {
                totaliZone[iz] = {
                  mt: 0,
                  sec: 0,
                };
              }
              totaliZone[iz].mt += +z[iz].mt;
              totaliZone[iz].sec += +z[iz].sec;
            }
          }
        }
      )

      let tot = {
        mt: 0,
        sec: 0,
      }
      for(let nomeZona in totaliZone){
        if (totaliZone[nomeZona]) {
          tot.mt += totaliZone[nomeZona].mt;
          tot.sec += totaliZone[nomeZona].sec;
        }
      }
      return tot;
    },

    formatAndatura: function (andatura) {
      let result = "";
      if (andatura.val_andatura) {
        result += " " + andatura.val_andatura;
      }
      if (andatura.des_zona) {
        result += " " + "in " + andatura.des_zona;
      }
      return result;
    }
  }
}
export default component;
</script>

<style lang="scss">
.msp-grafico-andature {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-end;
  padding: 3px 3px 0 3px;
  .dati-item {
    display: flex;
    flex: 1;
    white-space: nowrap;
    padding: 0 10px;
    align-items: center;
    background: var(--col-grigio-chiarissimo);
    gap: 3px;
    border-radius: 5px;
  }
  .dati-value {
    font-weight: normal;

  }
  .andatura {
    --height: 1;
    --width: 10;
    --scale-y: 100%;
    --scale-x: 1%;
    --color: #ccc;
    --color-hover: rgba(255,255,255,0.2);
    position: relative;
    width: calc(var(--scale-x) * var(--width));
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding:0px;
    &:hover {
        background: var(--color-hover);
    }
  }
  .andatura-bar {
      position: relative;
    background: var(--color);
      height: calc(var(--scale-y) * var(--height));
      width: 100%;
      position: relative;
  }

  .andatura-tooltip {
    position: absolute;
    display: none;
  }
  .andatura:hover .andatura-tooltip {
    display: block;
    left: 0;
    bottom: 100%;
    z-index: 100;
    padding: 10px;
    text-align: left;
    background: var(--col-grigio-scuro);
    color: #fff;
    border-radius: 5px;
    margin-bottom: 0px;
    white-space: nowrap;
    & .codice-zona {
      display: block;
      border-bottom: 1px solid #fff;
      margin-bottom: 5px;
    }
  }

  .dettaglio,
  .serie,
  .ripetuta,
  .andatura {
    display: flex;
    align-items: flex-end;
  }

}

</style>
